import React, { useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { PostalAddress, WithContext, Person, Store } from 'schema-dts';
import { Layout } from '../components/Layout';
import { Shop } from '../modules/Shop';

export interface State {
  name?: string;
  address?: {
    city: string;
    cepAddress: string;
    district: string;
    street: string;
  };
  urlImg?: string;
  phone?: string;
  whatsapp?: string;
  personName?: string;
  s3?: string;
  responseAds: any;
}

const initialState: State = {
  name: 'Loja',
  address: {
    city: '',
    cepAddress: '',
    district: '',
    street: '',
  },
  urlImg: '',
  phone: '',
  whatsapp: '',
  personName: '',
  s3: '',
  responseAds: null,
};

interface Props extends PageProps {
  data: {
    autoscarStore: any;
  };
  pageContext: {
    id: number;
  };
}

const Loja = ({ ...props }: Props) => {
  const {
    data: { autoscarStore },
    pageContext: { id },
  } = props;

  const [state, setState] = useState(initialState);

  const onStoreLoad = (newState: State) => {
    setState(newState);
  };

  const {
    name: stateName,
    address: stateAddress,
    personName: statePersonName,
    urlImg: stateUrlImg,
    phone: statePhone,
    whatsapp: stateWhatsapp,
    responseAds,
  } = state;

  const {
    fantasyName: autoscarStoreFantasyName,
    name: autoscarStoreName,
    urlCompanyLogo: autoscarStoreLogo,
    phone: autoscarStorePhone,
    whatsapp: autoscarStoreWhatsapp,
    urlFrontagePhoto: autoscarStoreUrlFrontagePhoto,
    city: autoscarStoreCity,
  } = autoscarStore;

  let addressSchema;

  if (stateAddress) {
    const { city, cepAddress, district, street } = stateAddress;
    addressSchema = {
      addressCountry: 'BRA',
      addressRegion: city,
      postalCode: cepAddress,
      streetAddress: `${street}${street && district ? `, ${district}` : district}`,
    } as PostalAddress;
  }

  const image = `${process.env.FTP_IMAGENS}${autoscarStoreLogo || stateUrlImg}`;

  const storeSchema: WithContext<Store> = {
    '@context': 'https://schema.org',
    '@type': 'Store',
    name: autoscarStoreFantasyName || stateName,
    logo: autoscarStoreUrlFrontagePhoto || stateUrlImg,
    address: addressSchema,
    telephone: autoscarStorePhone || statePhone,
    employee: {
      givenName: autoscarStoreName || statePersonName,
      telephone: autoscarStoreWhatsapp || stateWhatsapp,
    } as Person,
    url: props.location.href,
    image,
  };

  const title = responseAds
    ? `${responseAds.name} em ${responseAds.city} | Autoscar`
    : `${autoscarStoreFantasyName} em ${autoscarStoreCity} | Autoscar`;

  const storeName = responseAds ? responseAds.name : autoscarStoreFantasyName;

  return (
    <Layout
      title={title}
      description={`Encontre seu próximo veículo na ${storeName}. Compra e venda de motos e carros usados ou 0km é na Autoscar`}
      schema={storeSchema}
      image={image}
      url={props.location.href}
    >
      <Shop
        location={props.location}
        onLoad={onStoreLoad}
        autoscarStore={autoscarStore}
        pageContextId={id}
        dataValue={autoscarStoreFantasyName}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($id: Int) {
    autoscarStore(autoscarId: { eq: $id }) {
      autoscarId
      city
      state
      companyName
      cpfCnpj
      email
      fantasyName
      id
      name
      phone
      status
      type
      urlCompanyLogo
      urlFrontagePhoto
      whatsapp
      aliasName
      advertisementsCount
      address
      addressNumber
      additionalAddressData
      addressDistrict
      advertisements {
        id
        axles
        busType
        city
        cargoVehicleType
        coolingType
        color
        description
        loadCapacity
        marches
        matchType
        mileage
        model {
          brandName
          fabricationYear
          id
          modelYear
          motor
          name
          price
          status
          type
          version
        }
        motor
        optionals
        photoURL
        plate
        price
        state
        seatsAmount
      }
    }
  }
`;

export default Loja;
